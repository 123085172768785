import {
  AutoForm,
  AutoFormCheckboxGroupField,
  Button,
  Checkbox
} from "@9amhealth/shared";
import styled from "@emotion/styled";
import type { FC, ReactNode } from "react";
import React from "react";
import Translate from "src/ui/components/Translate/Translate";
import { z } from "zod";
import Loader from "../Loader/Loader";
import ResponsiveImage, {
  imageFromSrc
} from "../ResponsiveImage/ResponsiveImage";
import { toast, useBloc } from "src/state/state";
import UserCubit from "src/state/UserCubit/UserCubit";
import reportErrorSentry from "src/lib/reportErrorSentry";
import UserPreferencesCubit from "src/state/UserPreferencesCubit/UserPreferencesCubit";
import { APP_BREAKPOINT } from "src/constants/layout";

const CenterUi = styled.div`
  padding: 0.7rem 0.4rem 0;
  .responsive-image {
    aspect-ratio: 2.5;
    margin-bottom: 1rem;
    width: 100%;
  }
`;

const ContentWrap = styled.div`
  color: var(--greys-charcoal-80, rgba(33, 33, 33, 0.8));
  margin-top: 0.8rem;

  @media screen and (min-width: ${APP_BREAKPOINT}px) {
    margin-top: 1.5rem;
  }
`;

const Paragraph = styled.p`
  margin: 0 0 0.8rem;

  @media screen and (min-width: ${APP_BREAKPOINT}px) {
    margin: 0 0 1.5rem;
  }

  &[data-register="true"] {
    text-align: center;
  }
`;

const ButtonPosition = styled.div`
  display: flex;
  justify-content: end;
  margin-bottom: -1.4rem;
  margin-top: 1rem;
`;

const ConsentFormSchema = z.object({
  consentSet1: z.array(z.literal("consentSet1")),
  consentSet2: z.array(z.literal("consentSet2"))
});

const ConsentForm: FC<{
  onSuccess?: () => unknown;
  header?: ReactNode;
  isRegistering?: boolean;
}> = (props) => {
  const [, { acceptConsents }] = useBloc(UserCubit);
  const [loading, setLoading] = React.useState(false);
  const [, { firstName }] = useBloc(UserPreferencesCubit);

  const handleSubmit = (): void => {
    setLoading(true);
    void acceptConsents()
      .then(() => {
        props.onSuccess?.();
      })
      .catch((e: unknown) => {
        toast.error("error_generic");
        if (e instanceof Error) {
          reportErrorSentry(e.message);
        } else {
          reportErrorSentry(e);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <CenterUi>
      {props.header}
      <Loader active={loading}>
        <AutoForm schema={ConsentFormSchema} onSubmit={handleSubmit}>
          {!props.isRegistering && (
            <ResponsiveImage
              image={imageFromSrc(
                "https://cdn.sanity.io/images/g38rxyoc/production/c8f0e589ce45b22b920a91bc573a4d170e771ace-1750x875.webp"
              )}
            />
          )}
          {props.isRegistering && (
            <>
              <h1 className="as-h4-large" style={{ textAlign: "center" }}>
                <Translate msg="consents_update_title_registration" />
              </h1>
            </>
          )}
          <ContentWrap>
            <Paragraph data-register={props.isRegistering}>
              <Translate
                msg={
                  props.isRegistering
                    ? "consents_update_description_registration"
                    : "consents_update_description"
                }
                variables={{ nickname: firstName }}
              />
            </Paragraph>
            <br />
            <AutoFormCheckboxGroupField name="consentSet1">
              <Checkbox value={"consentSet1"}>
                <div>
                  <Translate msg="registerConsent.set1" />
                </div>
              </Checkbox>
            </AutoFormCheckboxGroupField>
            <br />
            <AutoFormCheckboxGroupField name="consentSet2">
              <Checkbox value={"consentSet2"}>
                <div>
                  <Translate msg="registerConsent.set2" />
                </div>
              </Checkbox>
            </AutoFormCheckboxGroupField>
            <ButtonPosition className="button-position">
              <Button
                type="submit"
                theme={props.isRegistering ? "sunrise" : "charcoal"}
              >
                <Translate msg="confirm" />
              </Button>
            </ButtonPosition>
          </ContentWrap>
        </AutoForm>
      </Loader>
    </CenterUi>
  );
};

export default ConsentForm;
